<!-- HomePage.vue -->
<template>
    <div>
      <HeroSection />
      <AboutSection />
      <ServicesSection />
      <ProjectsSection />
      <ContactSection />
    </div>
  </template>

<script>
import HeroSection from '@/components/HeroSection.vue';
import AboutSection from '@/components/AboutSection.vue';
import ServicesSection from '@/components/ServicesSection.vue';
import ProjectsSection from '@/components/ProjectsSection.vue';
import ContactSection from '@/components/ContactSection.vue';

export default {
  components: {
    HeroSection,
    AboutSection,
    ServicesSection,
    ProjectsSection,
    ContactSection,
  },
};
</script>

  
  <style scoped>
  /* Page specific styles */
  </style>
  