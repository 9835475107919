<!-- ContactSection.vue -->
<template>
    <section class="contact" id="contact">
      <h2>We’d Like to Discuss Your Project Needs</h2>
      <p>Let’s see if we might be a fit!</p>
      <button>Contact Us</button>
    </section>
  </template>
  
  <style scoped>
  .contact {
    padding: 40px 20px;
    text-align: center;
    background-color: #e67e22;
    color: #fff;
  }
  .contact button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  </style>
  