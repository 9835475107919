<!-- ProjectsSection.vue -->
<template>
    <section class="projects" id="projects">
      <h2>A Trusted Reputation Built Over Time</h2>
      <div class="project-info">
        <div>
          <h3>1k+</h3>
          <p>Completed Projects</p>
        </div>
        <div>
          <h3>30+</h3>
          <p>Years Experience</p>
        </div>
      </div>
    </section>
  </template>
  
  <style scoped>
  .projects {
    padding: 40px 20px;
    text-align: center;
    background-color: #f6f6f6;
  }
  .project-info {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  .project-info div {
    text-align: center;
  }
  .project-info h3 {
    font-size: 2rem;
    color: #e67e22;
  }
  </style>
  