<!-- App.vue -->
<template>
  <div id="app">
    <header class="header">
      <div class="container">
    <div class="header-left">
      <span class="brand">D&E Groundworks</span>
    </div>
    <nav class="header-right" :class="{ open: isMobileMenuOpen}">
        <a href="#about" @click="closeMenu">About</a>
        <a href="#services" @click="closeMenu">Services</a>
        <a href="#projects" @click="closeMenu">Projects</a>
        <a href="#contact" @click="closeMenu">Contact</a>
    </nav>
    <div class="menu-toggle" @click="toggleMenu">
      <span></span>
    </div>
  </div>
  </header>
    <main>
      <HomePage />
    </main>
    <footer>
      <p>&copy; 2024 D&E Groundworks - All Rights Reserved</p>
    </footer>
  </div>
</template>

<script>
import HomePage from "./views/HomePage.vue";

export default {
  components: {
    HomePage,
  },
  data() {
      return{
        isMobileMenuOpen: false,
      }
    },
  methods: {
    toggleMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    },
    closeMenu() {
      this.isMobileMenuOpen = false;
    },
  },
  mounted() {
    document.title = 'D&E Groundworks LTD';
  }
};

</script>

<style>
/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Arial, sans-serif;
  color: #333;
}

a {
  text-decoration: none;
  color: inherit;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #0d6fca;
  z-index: 1000;
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.brand {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.header-right{
  margin-left: 20px;
  color: #fff;
  font-weight: bold;
}

.header-right a{
  margin-left: 20px;
  color: #fff;
  font-weight: bold;
}

.header-right a:hover{
  color: #110fa1;
}

.menu-toggle{
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle span{
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: all 0.3s ease;
}

main {
  margin-top: 80px;
  flex: 1;
}

section {
  padding: 80px 20px;
}

footer{
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
}

@media (max-width: 768px) {
  .header-right{
    position: fixed;
    top: 65px;
    right: 0;
    background-color: #2427e7;
    flex-direction: column;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  .header-right.open{
    max-height: 200px;
  }

  .header-right a{
    margin:15px;
    font-size: 18px;
  }

  .menu-toggle{
    display: flex;
  }
}
</style>
