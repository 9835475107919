<!-- HeroSection.vue -->
<template>
    <section class="hero" id="hero">
      <h1>Groundwork and forecourt specialists</h1>
    </section>
  </template>
  
  <style scoped>
  .hero {
    background: url("@/assets/hero-image.jpg") no-repeat center center;
    background-size: cover;
    color: #fff;
    text-align: center;
    padding: 80px 20px;
    text-align: center;
    text-shadow: 8px 8px 10px rgba(0,1,1,0.9);
  }
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  .hero p {
    font-size: 1.2rem;
  }
  </style>
  