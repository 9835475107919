<!-- AboutSection.vue -->
<template>
    <section class="about" id="about">
      <h2>About Us</h2>
      <p>With over 30 years of experience in groundwork construction...</p>
      <button>Learn More</button>
    </section>
  </template>
  
  <style scoped>
  .about {
    padding: 40px 20px;
    text-align: center;
    background-color: #f6f6f6;
  }
  .about h2 {
    font-size: 2rem;
    color: #e67e22;
  }
  .about button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #e67e22;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  </style>
  