<!-- ServicesSection.vue -->
<template>
    <section class="services" id="services">
      <h2>Our Services</h2>
      <div class="service-grid">
        <div v-for="service in services" :key="service.title" class="service-card">
          <h3>{{ service.title }}</h3>
          <p>{{ service.description }}</p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        services: [
          { title: "Design", description: "Comprehensive design services for construction." },
          { title: "Development", description: "Complete site development solutions." },
          { title: "Engineering", description: "Professional engineering services." },
          { title: "Infrastructure", description: "Solid foundation and infrastructure work." },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .services {
    padding: 40px 20px;
    background-color: #fff;
    text-align: center;
  }
  .service-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  .service-card {
    width: 200px;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fafafa;
  }
  </style>
  